import React, { useState } from "react";
import "./Popup.style.css";
import axios from 'axios'
import { toast } from 'react-toastify'

const PopupWeb = () => {

  const collegeOptions = [
    "Anand International College of Engineering, Jaipur",
    "Arya College of Engineering, Jaipur",
    "Agra College",
    "Arya Institute of Engineering Technology & Management, Jaipur",
    "Amity University",
    "Apex University",
    "Arka Jain University, Jamshedpur",
    "Arya College of Engineering and I.T, Jaipur",
    "Banasthali Vidyapith",
    "Babu Banarasi Das Northern India Institute of Technology, Lucknow",
    "Biyani Group Of Colleges",
    "B K Birla Institute of Engineering & Technology, Pilani",
    "B.S.A. College of Engineering & Technology, Mathura",
    "BITS Pilani Goa campus",
    "BITS Pilani Jaipur campus",
    "Bikaner Technical University (BTU)",
    "CVR College of Engineering, Hyderabad",
    "Dr. D. Y. Patil Vidyapeeth, Pune",
    "Global institute of Technology (GIT), Jaipur",
    "Government Engineering College (GEC), Banswara",
    "Government women engineering College, Ajmer",
    "Government Engineering College, Bharatpur",
    "Jagannath University (JU)",
    "Jaipur Engineering College and Research Centre (JECRE)",
    "Jodhpur Institute of Engineering & Technology, Jodhpur",
    "Jaipur National University (JNU)",
    "Jayoti Vidyapeeth Women’s University (JVWU)",
    "Lovely Professional University (LPU)",
    "Manipal University Jaipur",
    "MITS Pali (Mahaveer Institute of Technology & Science)",
    "Modi University (Dr K.N. Modi University)",
    "M.B.M. Engineering College, Jodhpur",
    "Maharishi Arvind Institute of Science & Management, Jaipur",
    "Malaviya National Institute of Technology, Jaipur",
    "Poornima College of Engineering",
    "Poornima Institute of Engineering and Technology",
    "Poddar College (Poddar Group of Institutions)",
    "Poornima University",
    "Pratap Institute of Technology & Science",
    "Rajasthan College of Engineering for Women (RCEW)",
    "Rajasthan Technical University (RTU), Kota",
    "S. S. Jain [Subodh College (or S. S. Jain Subodh P. G. College)]",
    "Swami Keshvanand Institute of Technology (SKIT)",
    "Stani Memorial P.G College",
    "SRM University, Bhopal",
    "St. Xavier's College, Jaipur",
    "The ICFAI University",
    "University of Engineering & Management, Jaipur",
    "Vivekananda Global University (VGU)",
    "Vyas College of Engineering and Technology, Jodhpur",
    "Vyas Institute of Engineering and Technology, Jodhpur",
    "Other",
  ];
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [college , setCollege] = useState("")
  const  [isNotCollege , setIsNotCollege] =  useState(false)

  const [stat , setStat] = useState(0)

  const handleSubmit = (e) => {
    e.preventDefault()
    const data =  {
      name : name , email : email,  contact :  contact , selectedOption : selectedOption , college_name : college
    }

    if(name.trim() == "" )
      {
        toast.error("Please Enter Your Name")
      }
      else if(email.trim() == ""){


                toast.error("Please Enter Your Email")

      }
      else if(contact.trim() == ""){


                toast.error("Please Enter Your Mobile Number")

      }
      else if(!selectedOption){


                toast.error("Please Select Technology")

      }
      else if(college.trim() == "" || !college){


                toast.error("Please Enter Your College Name")

      }
      else{

    axios.post("https://internapi.learnandbuild.in/v2/add-bstf" , data).then((res)=>{
      if(res.data.status == 200)
      {
        setStat(res.data.status)
        toast.success(res.data.message)
       
      }
      
    }).catch((err)=>{
      toast.error(err.response.data.message)
    })

  }

    
    // try {
    //   const response = await fetch(
    //     "https://internapi.learnandbuild.in/v2/add-bstf",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ name, email, contact, selectedOption }),
    //     }
    //   );

    //   // console.log(response);

    //   if (response.data.status  === 200) {
    //     console.log("User registered successfully");

    //     // Close the modal
    //     // setModalVisible(false);
    //   } else {
    //     console.error("Failed to register user");
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };

  const getCollegeValue = (e)=>{
    console.log(e.target.value)
    if(e.target.value === "Other")
      {
        setIsNotCollege(true)
        setCollege("")
      }
      else{
        setCollege(e.target.value)
        setIsNotCollege(false)
      }
  }


  return (
    <div>
      <div className="centeredView">
        {modalVisible && (
          <div className="modalBackground">
            <div className="modalView">
              <div className="d-block d-lg-flex gap-2 p-2">
                <img
                  src="assets/Popup.png"
                  alt="popup-img"
                  className="rounded"
                  style={{ height: "auto", width: "100%", maxWidth: "400px" }}
                />
                <>
                { stat !== 200 ? 
                <form
                  className="d-flex flex-column justify-content-between p-5 form-popup h-100 w-100 ml-5 border rounded"
                  onSubmit={handleSubmit}
                >
                  <div className="d-flex flex-column h-100">
                    <div className="p-2 flex-grow-1">
                      <input
                        className="w-100 p-2 h-100 inputPopup"
                        type="text"
                        placeholder="Enter Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="p-2 flex-grow-1">
                      <input
                        className="w-100 p-2 h-100 inputPopup"
                        type="text"
                        placeholder="Enter Mobile Number"
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                      />
                    </div>
                    <div className="p-2 flex-grow-1">
                      <input
                        className="w-100 p-2 h-100 inputPopup"
                        type="text"
                        placeholder="Enter Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="p-2 flex-grow-1">
                      <select
                        value={selectedOption}
                        style={{ border: "none", height: "40px" }}
                        className="w-100 inputPopup"
                        onChange={(e) => setSelectedOption(e.target.value)}
                      >
                        <option value={null}>Select a Technology</option>
                        <option value="Java Core Programming">
                          Java Core Programming
                        </option>
                        <option value="Data Structure and Algorithms with Java">
                          Data Structure and Algorithms with Java
                        </option>
                        <option value="Java Core">Java Core</option>
                        <option value="Cloud Computing and AI with AWS">
                          Cloud Computing and AI with AWS
                        </option>
                        <option value="Data Analysis with Python, SQL & Power BI">
                          Data Analysis with Python, SQL & Power BI
                        </option>
                        <option value="Machine Learning And Artificial Intelligence">
                          Machine Learning And Artificial Intelligence
                        </option>
                        <option value="Fullstack in Data Science & AI">
                          Fullstack in Data Science & AI
                        </option>
                        <option value="Frontend Web Development with React">
                          Frontend Web Development with React
                        </option>
                        <option value="Backend Web Development with Node.js">
                          Backend Web Development with Node.js
                        </option>
                        <option value="Full Stack Web Development with MERN">
                          Full Stack Web Development with MERN
                        </option>
                        <option value="Salesforce Admin">
                          Salesforce Admin
                        </option>
                        <option value="Salesforce Development">
                          Salesforce Development
                        </option>
                        <option value="Salesforce Admin & Development">
                          Salesforce Admin & Development
                        </option>
                        <option value="Mobile App Development with Flutter">
                          Mobile App Development with Flutter
                        </option>
                        <option value="Ethical Hacking & Cyber Security">
                          Ethical Hacking & Cyber Security
                        </option>
                        <option value="Internet of Things (IOT) with AI">
                          Internet of Things (IOT) with AI
                        </option>
                      </select>
                    </div>



                    <div className="p-2 flex-grow-1">
                      <select
                        value={college}
                        style={{ border: "none", height: "40px" }}
                        className="w-100 inputPopup"
                        onChange={getCollegeValue}
                      >
                        <option value={null}>Select Your College</option>
                        {collegeOptions.map((el,i)=>(
    <option  value={el} >{el}</option>
    ))}
                      </select>
                    </div>
                    {isNotCollege ?
                    <div className="p-2 flex-grow-1">
                      <input
                        className="w-100 p-2 h-100 inputPopup"
                        type="text"
                        placeholder="Enter Your College Name"
                        value={college}
                        onChange={(e) => setCollege(e.target.value)}
                      />
                    </div>: null}




                  </div>
                  <div className="text-center mt-2 p-2">
                    <button className="btn btn-outline-primary" type="submit">
                      Get Best Offer{" "}
                      <img
                        src="assets/RedirectArrow.png"
                        style={{ width: "50px" }}
                        alt="arrow"
                      />
                    </button>
                  </div>
                </form> : <h5 style={{textAlign:"center" , color:"green"  ,marginTop : 200}}> We have Submitted your response <br></br> Soon you will get notified with our Best Offers via mail  </h5> }
                </>
              </div>
              <div className="text-center mt-3">
                <button
                  className="button btn btn-danger buttonClose"
                  onClick={() => setModalVisible(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
        <button
          className="button buttonClose  btn btn-primary"
          onClick={() => setModalVisible(true)}
        >
          Get Best Offer for You!
        </button>
      </div>
    </div>
  );
};

export default PopupWeb;
