import React from "react";

function WhoShouldJoin() {
  return (
    <section id="WhoShould" className="my-5 who_should_join">
      <div className="row m-0">
        <div className="col-12 col-md-4">
          <div className="image-container overflow-y">
            <img
              src="assets/working-leptop.png"
              alt="working-leptop"
              className="ms-3 img-who-should w-100"
            />
          </div>
        </div>
        <div className="WhoShouldJoin col-12 col-md-7 offset-0 offset-md-1 py-5">
          <h2 className="text-white text-start ">Who Should Join​?</h2>
          <ul className=" mt-5">
            <li className=" py-2 text-white lh-lg">
              B.Tech, MCA, M.Sc-IT, B.Sc-IT, BCA, PGDCA, And Diploma Courses
            </li>
            <li className=" py-2 text-white lh-lg">
              Undergraduate, Graduate, Or Post-Graduate Student
            </li>
            <li className=" py-2 text-white lh-lg">
              Student Seeking Industrial Training
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default WhoShouldJoin;
