import React from "react";
import CourseCard from "./Cards/CourseCard";

function Combo({ combo, props }) {
  return (
    <div className=" mt-5 container text-start">
      {/* <h1>Combos</h1> */}
      <h3 className="text-center mt-5 my-2">
        Join Our Unique Learning Combo Now!
      </h3>
      <div className="row row-cols-1 row-cols-md-3 g-4 mt-5">
        {combo.map((combo, index) => (
          <div className="col " key={index}>
            <CourseCard courses={combo} bro_name={combo.brochure} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Combo;
