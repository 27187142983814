import React from "react";
//
//
function Mentorship({ mentors }) {
  return (
    <div className="container my-5">
      <h3 className="text-center mb-5">Mentorship By Industry Experts</h3>
      <div className="row ">
        {mentors.map((mentor, index) => {
          return (
            <React.Fragment key={index}>
              <div
                key={index}
                className="col-12 col-md-3 text-center mt-3 mb-5"
              >
                <div className="card d-flex h-100">
                  <div className="p-2">
                    <img
                      src={mentor.img}
                      alt={mentor.name}
                      className="img-fluid align-self-center"
                      style={{
                        height: "200px",
                        width: "200px",
                        border: "4px solid #18A2FE",
                        borderRadius: "999px",
                      }}
                    />
                  </div>
                  <h5
                    style={{
                      font: "28px Source Sans Pro, sans-serif",
                      fontWeight: "600",
                      color: "#2E3138",
                    }}
                  >
                    {mentor.name}
                  </h5>
                  <div className="d-flex justify-content-center align-items-center">
                    <p
                      style={{
                        font: "25px Source Sans Pro, sans-serif",
                        color: "#2E3138",
                        minHeight: "90px",
                      }}
                    >
                      {mentor.subject}
                    </p>
                  </div>
                  <div className="container">
                    <hr />
                  </div>

                  <p
                    style={{
                      font: "22px Source Sans Pro, sans-serif",
                    }}
                    className="text-secondary"
                  >
                    {mentor.experience}
                  </p>
                </div>
                {/* <hr /> */}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Mentorship;
