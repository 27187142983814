import React from "react";

function DownloadApp() {
  return (
    <section>
      <div className="row m-0 mt-5">
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
          <div className="container w-50">
            <h1 className="text-heading-app">
              Download <br className="d-sm-none d-md-block" /> Our Mobile App
            </h1>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <div className="d-flex justify-content-end align-items-center">
                <img
                  src="assets/app_qr.png"
                  alt="App Qr"
                  className="img qr-code"
                />
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <a
                  href="https://play.google.com/store/apps/details?id=com.learn_build&pcampaignid=web_share"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="assets/google_play.png"
                    alt="Playstore"
                    className="img-fluid mt-3"
                  />
                </a>
                <h6
                  style={{
                    font: "20px Source Sans Pro, sans-serif",
                    color: "#2E3138",
                  }}
                  className="mt-3 p-2"
                >
                  Scan this QR code to
                  <br className="d-none d-md-inline" />
                  Download the App
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 pe-0 mt-5">
          <img src="assets/mobileapp.jpg" alt="mobile" className="img-fluid" />
        </div>
      </div>
    </section>
  );
}

export default DownloadApp;
