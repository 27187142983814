import React from "react";
import CourseCard from "./Cards/CourseCard";

function Technology({ technologies, props }) {
  return (
    <div className=" mt-5 container text-start">
      <h1>Technology</h1>
      <div className="row row-cols-1 row-cols-md-4 g-4  mx-auto mt-5">
        {technologies.map((technology, index) => (
          <div className="col " key={index}>
            <CourseCard courses={technology} bro_name={technology.brochure}  />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Technology;
