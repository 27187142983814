import React from "react";

function AlumniCard({ students, props }) {
  return (
    <div className="card p-2 w-75 d-flex justify-content-between bg-light text-center mb-5">
      <img src={students.image} className="" alt={students.name} />
      <h4 className="alumniH1 p-2">{students.name}</h4>
      <h5 className="alumniH2 text-secondary">{students.company}</h5>
    </div>
  );
}

export default AlumniCard;
