import React from "react";

import ScrollIntoView from "react-scroll-into-view";

function Features({ features }) {
  return (
    <div id="FeaturesP">
      <h2 className="text-center mt-4 mb-5">Features</h2>
      <div className="container">
        <div className="row gap-2 p-3">
          {features.map((feature, index) => (
            <div
              key={index}
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                minHeight: "300px",
              }}
              className="col-12 card  col-md-3 mt-3 mx-auto mb-5 px-2 py-2"
            >
              <img
                src={feature.img}
                alt={feature.title}
                className="img-fluid mt-3"
                style={{
                  objectFit: "contain",
                  aspectRatio: "3/2",
                  width: "18%",
                }}
              />
              <div className="p-3">
                <h6
                  style={{ font: "26px 'Source Sans Pro', sans-serif" }}
                  className="mt-2 mb-3 fw-bold"
                >
                  {feature.title}
                </h6>
                <p
                  style={{
                    font: "18px 'Source Sans Pro', sans-serif",
                    lineHeight: "127%",
                  }}
                >
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-5 mb-4">
          <p
            style={{
              font: "35px 'Source Sans Pro', sans-serif",
              fontWeight: "bold",
            }}
            className="fs-4 text-muted mt-3"
          >
            Experience the difference that Learn and Build can make in your
            career!
          </p>
          <ScrollIntoView selector="#formRegister">
            <button className="btn btn-primary py-3 px-5">Join Now</button>
          </ScrollIntoView>
        </div>
      </div>
    </div>
  );
}

export default Features;
