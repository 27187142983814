import React from "react";
import Home from "./Pages/Home";

// import "../src/Assets/Css/downloadSyllabus.css";

function App() {

  return (
    <>
    <Home />;
    </>

  )
}

export default App;
