import React from "react";
import DefaultSlider from "./Swiper/DefaultSlider";
import { SwiperSlide } from "swiper/react";
import AlumniCard from "./Cards/AlumniCard";

function Alumni({ alumnis, props }) {
  return (
    <div className="container">
      <h1>Alumni Networks</h1>
      <DefaultSlider
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
      >
        
        {alumnis.map((alumni, index) => {
          return (
            <SwiperSlide
              key={index}
              className="d-flex justify-content-center mt-5 "
            >
              <AlumniCard students={alumni} />
            </SwiperSlide>
          );
        })}
      </DefaultSlider>
    </div>
  );
}

export default Alumni;
