import React from "react";

function FAQ() {
  return (
    <div className="row m-0 pt-5" id="FAQs">
      <h3 className="text-center py-4 mt-5">Frequently Asked Questions</h3>
      <div className="col-12 col-md-6 text-center">
        <img src="assets/faq.png" alt="FAQ" className="img-fluid w-75 " />
      </div>
      <div className="col-12 col-md-6">
        <div className="accordion px-3 " id="accordionExample">
          <div className="accordion-item border-0 mt-5 mb-3 shadow ">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h5>
                  Why should you join the Learn and Build Summer Training 2024
                  Program?
                </h5>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  <b> Experienced Faculty</b>: Our program has over 50
                  experienced and highly qualified faculty members who have an
                  average of 10 years of experience in their respective fields.
                  <br />
                  <br />
                  <b>Industry-Relevant Curriculum</b>: Our program offers
                  training in over 12 different courses in the latest
                  industry-relevant skills and technologies. We update our
                  curriculum every six months to ensure that our participants
                  receive training in the most current and in-demand areas.
                  <br />
                  <br />
                  <b>Personalized Learning</b>: Our class sizes are limited to
                  25 participants per batch, ensuring that each participant
                  receives personalized attention from our faculty members.
                  Additionally, we offer one-on-one mentorship sessions to help
                  our participants achieve their learning goals.
                  <br />
                  <br />
                  <b>Hands-On</b> Training: Our program provides over 100 hours
                  of hands-on training in real-world projects to ensure that our
                  participants are well-prepared for their future careers.
                  <br />
                  <br />
                  <b>Placement Assistance</b>: Our program has a success rate of
                  over 90% in securing internships and job opportunities for our
                  participants. We work with over 50 industry partners to
                  provide placement assistance and have placed over 10,000
                  participants in internships and jobs since 2012.
                  <br />
                  <br />
                  <b>Proven Track Record</b>: Since 2012, Learn and Build has
                  served over 200,000 participants in various workshop and
                  training programs. We have received over 95% positive feedback
                  from our participants and have provided Course completion
                  certificate and Internship Certificate in Association with 20+
                  Internship Partners to more than 200,000+ participants.
                  <br />
                  <br />
                  Overall, the Learn and Build Summer Training 2024 Program
                  provides a comprehensive learning experience that combines
                  theory and practical training with experienced faculty
                  members, industry-relevant curriculum, personalized learning,
                  hands-on training, placement assistance, and a proven track
                  record.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h5>
                  Who can do this Summer Training Program 2024 / Eligibility for
                  the Program?
                </h5>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  <b>Meant for Technology Students </b>: Our Summer Training
                  Program 2024 is designed specifically for students studying
                  technology courses, including B.Tech., MCA, M.Sc (IT), B.Sc
                  (IT), BCA, PGDCA, Diploma, and more.
                  <br />
                  <br />
                  <b>Students of All Years </b>: We welcome students studying in
                  1st year to final year to undergo our Summer Training Program
                  2024. Our program is designed to cater to the needs of
                  students at various stages of their academic journey.
                  <br />
                  <br />
                  <b>Meets Industrial Training Requirements </b>: Our program
                  meets the industrial training requirements of 2nd, 4th, and
                  6th semester students who are required to undergo industrial
                  training as part of their curriculum.
                  <br />
                  <br />
                  <b>Skill Upgradation</b>: Students who wish to upgrade their
                  existing skills can also undergo our program. Our program
                  covers a range of courses in the latest technologies and
                  provides hands-on training to help students develop practical
                  skills.
                  <br />
                  <br />
                  Overall, our Summer Training Program 2024 is designed
                  specifically for students studying technology courses and
                  provides them with practical training in the latest
                  technologies. We welcome students from all years of their
                  academic journey to undergo our program, including those who
                  need to meet their industrial training requirements and those
                  who wish to upgrade their existing skills.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h5>Who will be the trainers?</h5>
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  <b>Experienced Trainers</b>: Our Summer Training Program 2024
                  is conducted by a team of experienced trainers who have
                  extensive experience in the corporate sector and industry.
                  They have worked with top companies and have a deep
                  understanding of the latest technologies used in the industry.{" "}
                  <br />
                  <br />
                  <b>Industrial Exposure</b>: Our trainers provide participants
                  with practical exposure to the latest technologies used in the
                  industry, which helps prepare them for real-world job
                  scenarios. They share their insights and experiences with
                  participants, helping them gain a better understanding of the
                  industry. <br />
                  <br />
                  <b>Relevance to Industry Standards</b>: Our trainers ensure
                  that our training is aligned with industry standards, so
                  participants receive training that is both relevant and
                  practical. They are familiar with the latest industry trends
                  and ensure that our training programs are up-to-date.
                  <br />
                  <br />
                  <b>Ongoing Support</b>: Our trainers are available to provide
                  ongoing support to participants throughout their training and
                  internship, ensuring that they receive the guidance they need
                  to succeed. They are approachable and always ready to help
                  participants with any queries or concerns.
                  <br />
                  <br />
                  <b>Passionate and Knowledgeable:</b> Our trainers are
                  passionate about sharing their knowledge and expertise with
                  others and are dedicated to helping participants achieve their
                  goals. They have a strong commitment to training and
                  mentoring, and their enthusiasm for their work is infectious.
                  <br />
                  <br />
                  <b>Technology Expertise</b>: Our trainers have a wealth of
                  knowledge and expertise in the latest technologies used in the
                  industry, ensuring that participants receive training that is
                  up-to-date and relevant. They are constantly updating their
                  skills to stay abreast of the latest industry trends.
                  <br />
                  <br />
                  <b>Communication Skills</b>: Our trainers have excellent
                  communication skills and are able to explain complex concepts
                  in a clear and concise manner. They are patient and ensure
                  that participants understand the concepts thoroughly before
                  moving on to the next topic.
                  <br />
                  <br />
                  <b>Interactive Training Sessions:</b> Our trainers conduct
                  interactive training sessions, which encourage participation
                  and engagement from participants. They create a positive
                  learning environment that promotes teamwork and collaboration.
                  <br />
                  <br />
                  <b>Career Mentors</b>: Our trainers also act as career
                  mentors, guiding participants on various career paths in the
                  technology industry. They provide valuable insights on job
                  opportunities, skill requirements, and industry trends,
                  helping participants make informed decisions about their
                  careers.
                  <br />
                  <br />
                  <b>Industry Networking</b> : Learn and Build's trainers are
                  all industry experts with years of experience in their
                  respective fields. They bring real-world experience to the
                  training, which will give you practical knowledge that you can
                  apply to your career. Our trainers also provide personalized
                  guidance and mentorship to help you develop your skills and
                  achieve your career goals. Plus, they can help you build your
                  professional network, which can be valuable for your future
                  career.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <h5>
                  How will this Project Based Summer Industrial Training &
                  Internship program benefit me?
                </h5>
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  <b>Gain practical knowledge:</b> Through this program, you
                  will have the opportunity to apply the theories you have
                  learned in your academic courses to real-world projects. This
                  will help you gain hands-on experience and practical skills
                  that are highly valued by employers.
                  <br />
                  <br />
                  <b>Become job-ready:</b> Our Project Based Summer Industrial
                  Training & Internship program is designed to help transform
                  you from a student to a working professional. You will have
                  the chance to work on industry-relevant projects, gain
                  exposure to corporate culture and work ethics, and develop
                  soft skills that are essential for success in any workplace.
                  <br />
                  <br />
                  <b>Skill development:</b> By participating in this program,
                  you will not only learn new technical skills but also enhance
                  your overall skill set. You will work on projects that require
                  teamwork, communication, problem-solving, and time-management
                  skills. These skills are critical for your personal and
                  professional growth and will make you a well-rounded
                  individual.
                  <br />
                  <br />
                  <b>Networking opportunities:</b> Our mentors are industry
                  experts who can guide you in building your professional
                  network. They can provide you with insights into the industry
                  and connect you with potential employers. This can be an
                  excellent opportunity to build relationships with
                  professionals in your field and gain valuable contacts for
                  future job opportunities.
                  <br />
                  <br />
                  <b>Industry exposure:</b> By participating in this program,
                  you will gain exposure to the latest industry trends,
                  technologies, and practices. You will have the opportunity to
                  work on real-world projects and learn from experienced
                  professionals. This exposure can be invaluable in helping you
                  stay up-to-date with the latest developments in your field and
                  make informed career choices.
                  <br />
                  <br />
                  <b>Internship and certification:</b> On successful completion
                  of the program, you will receive an internship certificate and
                  course completion certificate, which will add value to your
                  resume and increase your chances of getting hired.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <h5>
                  What is the duration of the Summer Industrial Training
                  Program?
                </h5>
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  The Summer Industrial Training Program is available in two
                  different durations: <b>30 days and 45 days</b>, giving you
                  the flexibility to choose a time frame that best suits your
                  needs.
                  <br />
                  <br />
                  The program is designed to provide you with
                  <b> hands-on learning </b>
                  experience in a real-world setting.
                  <br />
                  <br />
                  You will have the opportunity to work on <b>live projects </b>
                  that will help you gain practical knowledge and skills.
                  <br />
                  <br />
                  The duration of the program (30 or 45 days) will give you
                  ample time to <b> learn, practice </b>, and refine your skills
                  under the guidance of expert trainers.
                  <br />
                  <br />
                  By the end of the program, you will have a solid understanding
                  of the concepts, tools, and techniques used in the industry,
                  <b> making you job-ready</b>.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                <h5>
                  Is there a fee for the Summer Industrial Training Program?
                </h5>
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  The Summer Industrial Training Program is a{" "}
                  <b>paid program</b> .
                  <br /> <br />
                  The program fee is <b>affordable and reasonable </b>
                  considering the value it offers to the participants.
                  <br />
                  <br /> The fee structure is transparent and there are
                  <b> no hidden costs </b> involved. <br />
                  <br />
                  We offer <b>multiple payment options</b> to make it convenient
                  for the participants to pay the fees. <br />
                  <br />
                  The fee for the program includes the
                  <b> cost of training, project materials, and certificate.</b>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseseven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                <h5>
                  Can I choose between online and physical mode for the Summer
                  Industrial Training?
                </h5>
              </button>
            </h2>
            <div
              id="collapseseven"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  The Summer Industrial Training Program is a paid program.{" "}
                  <br /> <br />
                  The program fee is affordable and reasonable considering the
                  value it offers to the participants.
                  <br />
                  <br /> The fee structure is transparent and there are no
                  hidden costs involved. <br />
                  <br />
                  We offer multiple payment options to make it convenient for
                  the participants to pay the fees. <br />
                  <br />
                  The fee for the program includes the cost of training, project
                  materials, and certificate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
