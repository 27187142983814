import React from "react";
import DefaultSlider from "./Swiper/DefaultSlider";
import { SwiperSlide } from "swiper/react";
import "swiper/css";

function RealStory({ stories }) {
  return (
    <section className="my-5">
      <div className="text-center mt-5 mb-3">
        <h3>Real Interns, Real Stories</h3>
        <div className="d-flex justify-content-center position-relative m-auto">
          <p className="heading-story">
            Discover the Impact Our Internship Program Has Made
          </p>
          <img
            src="assets/arrow_line.png"
            className="mt-2 ms-2 d-md-block d-none"
            alt="arrow_line"
          />
        </div>
      </div>
      <div className="px-2 px-md-5">
        <DefaultSlider
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
          {stories.map((story, index) => {
            return (
              <SwiperSlide key={index} className="py-5 ">
                <div className="card py-3 shadow  card-story">
                  <div className="card-body ">
                    <div
                      className="content d-flex"
                      style={{ minHeight: "130px" }}
                    >
                      <div className="mx-2 ">
                        <img src="assets/comma.png" alt="story" />
                      </div>
                      <p
                        style={{
                          font: "16px Source Sans Pro, sans-serif",
                          lineHeight: "150%",
                        }}
                      >
                        {story.message}
                      </p>
                    </div>
                  </div>
                  <div className="card-footer bg-white border-0 py-0 mx-5 mb-4">
                    <img
                      src={story.image}
                      alt="user"
                      className="img story-image"
                    />
                    <span className="mx-2 fw-bold">{story.name}</span>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </DefaultSlider>
      </div>
    </section>
  );
}

export default RealStory;
