// import React from "react";
import React from "react";
import { FloatButton } from "antd";
import { WhatsAppOutlined, PhoneOutlined } from "@ant-design/icons";

function WAButton() {
  return (
    <>
      <FloatButton.Group shape="circle" style={{ right: 24 }}>
        <FloatButton
          style={{
            backgroundColor: "#25D366",
            borderColor: "#25D366",
            marginBottom: 16,
          }}
          icon={<WhatsAppOutlined />}
          href="https://wa.me/+919462711393"
          target="_blank"
        />
        <FloatButton
          style={{ backgroundColor: "#007bff", borderColor: "#007bff" }}
          icon={<PhoneOutlined />}
          href="tel:18008898562"
          target="_blank"
        />
        <FloatButton.BackTop
          style={{ backgroundColor: "#be9e52", borderColor: "#be9e52" }}
        />
      </FloatButton.Group>
    </>
  );
}

export default WAButton;
