import React from "react";
import { Swiper } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Scrollbar } from "swiper/modules";
import SwiperCore from "swiper";

SwiperCore.use([Scrollbar]);
function DefaultSlider({ children, breakpoints }) {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={6}
      onSlideChange={() => {
        //
      }}
      onSwiper={(swiper) => {
        // console.log(swiper)
      }}
      loop={true}
      breakpoints={breakpoints}
      autoplay={{
        delay: 2500,
        disableOnInteraction: true,
      }}
      modules={[Autoplay]}
      scrollbar={{ draggable: true }}
    >
      {children}
    </Swiper>
  );
}

export default DefaultSlider;
