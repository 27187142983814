import React from "react";

function Footer() {
  return (
    <div
      className="d-flex flex-column"
      style={{
        backgroundColor: "#2E3138",
        color: "#fff",
      }}
    >
      <div className=" lh-lg p-3 text-center">
        <div>
          <img
            src="./assets/lnb-logo.png"
            alt="logo"
            className="img img-fluid p-0   "
          />
        </div>
        <div className="d-flex justify-content-center lh-lg gap-5 FooterContent">
          <p className="">
            <a href="https://learnandbuild.in/about/"> About Us </a>
          </p>
          <p>
            <a href="https://learnandbuild.in/contact/"> Contact Us </a>
          </p>
          <p>
            <a href="#0"> Blog</a>
          </p>
          <p>
            <a href="https://learnandbuild.in/career/"> Career </a>
          </p>
        </div>
        <div>
          <div>
            <div className="d-flex justify-content-center">
              <hr style={{ width: "10%" }} />
            </div>

            <div className="d-flex justify-content-center gap-2 mt-2">
              <a
                href="https://www.facebook.com/lnbuildcore?mibextid=ZbWKwL"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/fb.png" alt="img-social" />
              </a>

              <a
                href="https://twitter.com/lnbcore"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/twitter.png" alt="img-social" />
              </a>
              <a
                href="https://www.instagram.com/lnbcore"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/Instagram.png" alt="img-social" />
              </a>
              <a
                href="https://www.linkedin.com/company/learn-and-build/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/linkedin.png" alt="img-social" />
              </a>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <hr className="w-50" />
          </div>
          <div>
            <p>Copyright © 2024 lnb All Rights Reserved.</p>
          </div>
        </div>
      </div>
      {/* col */}
    </div>
  );
}

export default Footer;
