import React from "react";
import PopupWeb from "./Popup/Popup";

const FooterWithModal = () => {
  return (
    <footer style={footerStyle} className="my-auto p-3">
      <PopupWeb />
    </footer>
  );
};

const footerStyle = {
  position: "fixed",
  bottom: 0,
  width: "100%",
  //   height: "70px",
  //   backgroundColor: "#f8f9fa",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  zIndex: "999",
  //   borderTop: "1px solid #dee2e6",
};

export default FooterWithModal;
