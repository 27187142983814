import React from "react";
import SwiperCore from "swiper";
import { Scrollbar } from "swiper/modules";
import DefaultSlider from "./Swiper/DefaultSlider";
import { SwiperSlide } from "swiper/react";

SwiperCore.use([Scrollbar]);

function Companies({ companies, props }) {
  return (
    <>
      <div className="py-5">
        <h3 className="my-4 text-center">Companies</h3>
        <DefaultSlider
          loop={true}
          autoplay={{ delay: 1000, disableOnInteraction: false }}
          speed={10000}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 60,
            },
          }}
          scrollbar={{ draggable: true }}
        >
          {companies.map((company, index) => {
            return (
              <SwiperSlide key={index}>
                <img
                  src={company.logo}
                  className="w-75 mb-5"
                  alt={company.name}
                  style={{
                    objectFit: "contain",
                    aspectRatio: "3/2",
                    mixBlendMode: "color-burn",
                  }}
                />
              </SwiperSlide>
            );
          })}
        </DefaultSlider>
      </div>
    </>
  );
}

export default Companies;
