// import { notification } from "antd";
import React from "react";

function GetInTouch() {
  return (
    <section className="my-5">
      <div className="container">
        <h3>Get In Touch</h3>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.301404397555!2d75.78974577615618!3d26.862163476677328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db543851ec463%3A0xe43fc00e725ff2a0!2sLearn%20and%20Build!5e0!3m2!1sen!2sin!4v1708415076267!5m2!1sen!2sin"
          width="100%"
          height="600px"
          className="maps-sec"
          allowfullscreen="1"
          loading="lazy"
          title="My Map"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
}

export default GetInTouch;
