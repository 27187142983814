import React from "react";
import ScrollIntoView from "react-scroll-into-view";
import Combo from "./Combo";

function WhyChoose() {
  return (
    <div className="container py-3">
      <h1 className="text-center py-4 mb-5">Why To Choose LnB</h1>
      <div className="row">
        <div className=" col-12 col-md-6">
          <div
            style={{
              font: "20px Source Sans Pro, sans-serif",
              color: "#2E3138",
            }}
            className="ChooseLnB shadow p-4 border rounded mb-5 ps-5"
          >
            Learn And Build Formerly Known As TechieNest Is India’s Most
            Valuable And Biggest Summer Internship Providing Platform.
          </div>
          <div
            style={{
              font: "20px Source Sans Pro, sans-serif",
              color: "#2E3138",
            }}
            className="ChooseLnB shadow p-4 border rounded mb-5 ps-5"
          >
            We Have Provided Internships To 1 Lakh+ Students And 10000+ Job
            Placements In Last 10 Years.
          </div>
          <div
            style={{
              font: "20px Source Sans Pro, sans-serif",
              color: "#2E3138",
            }}
            className="ChooseLnB shadow p-4 border rounded mb-5 ps-5 "
          >
            Master Coding, Technology, & DSA this summer with Learn and Build.
            Hands-On Practicals, Build Industry-Level Projects & Gain Practical
            Knowledge For A Successful Career.
          </div>
        </div>
        <div className="col-12 col-md-4 text-center offset-md-1">
          <img
            src="assets/whytochoose_lnb.png"
            alt="Why to choose LNB"
            className="w-100"
          />
        </div>
      </div>

      <div className="text-center mt-4">
        <ScrollIntoView selector="#formRegister">
          <button className="btn btn-primary btn-lg">Join Now</button>
        </ScrollIntoView>
      </div>
    </div>
  );
}

export default WhyChoose;
