
import React, { useState } from 'react';
import './verify.css';




function VerifyCertificate(){

    const [certificateNumber, setCertificateNumber] = useState('');
    const [certificateInfo, setCertificateInfo] = useState(null);
  
    const handleInputChange = (e) => {
      setCertificateNumber(e.target.value);
    };
  
    const handleVerify = () => {
      // Dummy data for demonstration purposes
      const dummyData = {
        name: 'John Doe',
        dob: '1990-01-01',
        course: 'React Development',
        duration: '3 Months'
      };
  
      if (certificateNumber === '12345') {
        setCertificateInfo(dummyData);
      } else {
        setCertificateInfo(null);
      }
    };
  
    return (
      <div className="App-verify">
        <header className="App-header-verify">
          <img src="https://internship.learnandbuild.in/assets/logo-lnb.png" className="App-logo-verify" alt="company logo" />
          <h1>Certificate Verification</h1>
        </header>
        <main className="main-verify">
          <input
            type="text"
            placeholder="Enter Certificate Number"
            value={certificateNumber}
            onChange={handleInputChange}
            className="input-verify"
          />
          <button className="button-verify" onClick={handleVerify}>Verify</button>
          {certificateInfo && (
            <div className="certificate-info-verify">
              <p><strong>Name:</strong> {certificateInfo.name}</p>
              <p><strong>Date of Birth:</strong> {certificateInfo.dob}</p>
              <p><strong>Course:</strong> {certificateInfo.course}</p>
              <p><strong>Duration:</strong> {certificateInfo.duration}</p>
            </div>
          )}
        </main>
      </div>
    );


}


export default VerifyCertificate