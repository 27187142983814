import React from "react";
import CourseCard from "./Cards/CourseCard";

function Coding({ Coding, props }) {
  return (
    <div id="syllabusD" className="container mt-5">
      <h1>Coding/Programming</h1>
      <div className="row row-cols-1 row-cols-md-3 g-4 mt-5">
        {Coding.map((coding, index) => (
          <div className="col" key={index}>
            <CourseCard courses={coding} bro_name={coding.brochure} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Coding;
