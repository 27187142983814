import React from "react";
import DefaultSlider from "./Swiper/DefaultSlider";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
// import "swiper/swiper.min.css";

function Colleges({ colleges, props }) {
  return (
    <>
      <div className="py-5">
        <h3 className="my-4 text-center">Colleges</h3>
        <DefaultSlider
          loop={true}
          autoplay={{ delay: 1000, disableOnInteraction: false }}
          speed={10000}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 60,
            },
          }}
          scrollbar={{ draggable: true }}
        >
          {colleges.map((college, index) => {
            return (
              <SwiperSlide key={index}>
                <img
                  src={college.logo}
                  className="w-100 mb-5"
                  alt={college.name}
                  style={{
                    objectFit: "contain",
                    aspectRatio: "3/2",
                    mixBlendMode: "color-burn",
                  }}
                />
              </SwiperSlide>
            );
          })}
        </DefaultSlider>
      </div>
    </>
  );
}

export default Colleges;
